import React, { useEffect } from "react";
import { useId } from "react";
import $ from "jquery";
import "select2";
import "select2/dist/css/select2.min.css";

const SearchableSelect = ({
  inputProps = {},
  options = [],
  optionProps = {},
  readOnlyFields = false,
}) => {
  const selectId = useId();

  useEffect(() => {
    let el = "";
    if (inputProps) {
      el = document.getElementById(selectId);
      if (el) {
        if (inputProps?.min_inputlength) {
          $(el).select2({
            minimumInputLength: inputProps?.min_inputlength,
          });
        } else {
          $(el).select2();
        }
        $(el).on("change", function () {
          const value = $(this).val();
          inputProps.onChange({
            target: { name: inputProps.name, value: value },
          });
        });
        $(el).on("select2:opening", function (e) {
          if (readOnlyFields) e.preventDefault();
        });
      }
    }
    return () => {
      // Unbind any event handlers attached to the element
      $(el).select2("destroy").off();
    };
  }, [inputProps, options, readOnlyFields, selectId]);

  return (
    <>
      <select {...inputProps} id={selectId}>
        <option value="">
          {inputProps.placeholder ? inputProps.placeholder : "Select"}
        </option>
        {options.map((option, key) => (
          <option
            key={key}
            value={
              typeof option === "object"
                ? optionProps && optionProps?.valueKey in option
                  ? option[optionProps.valueKey]
                  : "value" in option
                  ? option.value
                  : option.text
                : option
            }
          >
            {typeof option === "object"
              ? optionProps && optionProps?.textKey in option
                ? option[optionProps.textKey]
                : option.name || option.text || option.option
              : option}
          </option>
        ))}
      </select>
    </>
  );
};

export default SearchableSelect;
