import ScaleLoader from "react-spinners/ScaleLoader";

export default function Loader({
  color = "#fff",
  height = 20,
  width = 5,
}) {
  return (
    <ScaleLoader
      color={color}
      loading={true}
      height={height}
      width={width}
    />
  );
}
