import React, { Fragment, useEffect, useState } from "react";
import {
  permissionFixture,
  roleDetailsFixture,
  rolePermissionsFormFixture,
  rolesTableHeaderFixture,
} from "./utilities/fixture";
import TableArea from "../../components/DataTable/TableArea";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { toast } from "react-toastify";
import { isActionAllowed, onChangeInputData } from "../../utilities/helpers";
import Modal from "../../components/Modal";
import RolePermission from "./RolePermission";

const RolesManagement = () => {
  const [userRoles, setUserRoles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [permissionModal, setPermissionModal] = useState(false);
  const [roleDetails, setRoleDetails] = useState(roleDetailsFixture);
  const [loading, setLoading] = useState(false);
  const [permissionDetails, setPermissionDetails] = useState(
    rolePermissionsFormFixture
  );

  const getUserRoles = async () => {
    if (loading) return;
    setLoading(true);

    const { success, data } = await getDataWithAuth({
      api_url: endpoints["getRoles"],
    });
    setLoading(false);
    if (success && data) {
      setUserRoles(data);
    }
  };

  const handleEdit = (roleData) => {
    setRoleDetails({ id: roleData["id"], name: roleData["name"] });
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      const { success, message, errors } = await postDataWithAuth({
        api_url: roleDetails["id"]
          ? endpoints["updateRole"]
          : endpoints["addRole"],
        data: { ...roleDetails },
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        await getUserRoles();
        setOpenModal(false);
      } else if (errors) {
        if (typeof errors === "object")
          toast.error(errors[Object.keys(errors)[0]][0]);
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const handleValidation = () => {
    if (roleDetails.name) return true;
    else toast.error("User role is required.");
  };

  const deleteRole = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteRole"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("Role deleted successfully");
      setUserRoles((prev) => prev.filter((item) => item.id !== id));
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handlePermissionUpdate = async () => {
    if (loading || !roleDetails["id"]) return;
    let count = 0;
    let body = {
      id: roleDetails["id"],
    };
    for (let pKey in permissionDetails) {
      body[pKey] = {};
      for (let cKey in permissionDetails[pKey]) {
        if (permissionDetails[pKey][cKey]) count++;
        body[pKey][cKey] = permissionDetails[pKey][cKey] ? 1 : 0;
      }
    }
    if (count > 0) {
      setLoading(true);
      const { success } = await postDataWithAuth({
        api_url: endpoints["updateRolePermission"],
        data: body,
      });
      setLoading(false);
      if (success) {
        toast.success("Permissions updated successfully");
        await getUserRoles();
        onPermissionModalClose();
      } else {
        toast.error("Something went wrong!");
      }
    } else toast.info("Please choose permission");
  };

  const onPermissionModalClose = () => {
    setPermissionDetails(rolePermissionsFormFixture);
    setPermissionModal(false);
  };

  const onPermissionModalOpen = (rowData) => {
    setRoleDetails(rowData);
    let obj = {};
    for (let i = 0; i < permissionFixture.length; i++) {
      if (rowData[permissionFixture[i]["key"]]) {
        obj[permissionFixture[i]["key"]] = JSON.parse(
          rowData[permissionFixture[i]["key"]]
        );
      }
    }
    if (Object.keys(obj).length) {
      setPermissionDetails(obj);
    }
    setPermissionModal(true);
  };

  useEffect(() => {
    getUserRoles();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Roles Management List"
          tableHeadersFixture={rolesTableHeaderFixture}
          data={userRoles}
          loading={loading}
          onSetPermission={(rowData) => onPermissionModalOpen(rowData)}
          onDelete={(rowData) => deleteRole(rowData["id"])}
          onEdit={(rowData) => handleEdit(rowData)}
        >
          <div className="button-group filter-button justify-content-md-end">
            {isActionAllowed("create") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => {
                  setRoleDetails(roleDetailsFixture);
                  setOpenModal(true);
                }}
              >
                Add Role
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      {/* Add role popup  */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        header={roleDetails["id"] ? "Update Role" : "Add Role"}
        modal_classes="add-role-popup filter-popup-medium"
      >
        <form>
          <div className="mb-3 row">
            <div className="col-12">
              <label>User Role</label>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="name"
                  value={roleDetails.name}
                  className="form-control"
                  placeholder="Enter Role"
                  onChange={(e) =>
                    onChangeInputData(e, roleDetails, setRoleDetails)
                  }
                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="button-group display-flex justify-content-end mt-5">
                <button
                  type="button"
                  className="btn btn-primary btn-primary-border "
                  disabled={loading}
                  onClick={() => {
                    setRoleDetails({});
                    setOpenModal(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>

      {/* Permission popup */}
      <Modal
        open={permissionModal}
        onClose={onPermissionModalClose}
        header={"Set Permission"}
        modal_classes="roles-management-popup filter-popup-medium"
      >
        <div>
          {roleDetails["id"] &&
            permissionFixture.map((current, ind) => (
              <Fragment key={ind}>
                <RolePermission
                  text={current.label}
                  keyProp={current.key}
                  permissionDetails={permissionDetails}
                  setPermissionDetails={setPermissionDetails}
                />
              </Fragment>
            ))}
          <div className="button-group display-flex justify-content-end mt-5">
            <button
              type="button"
              className="btn btn-primary btn-primary-border "
              disabled={loading}
              onClick={onPermissionModalClose}
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              disabled={loading}
              onClick={handlePermissionUpdate}
            >
              Save
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default RolesManagement;
