import React, { Fragment, useEffect, useState } from "react";
import CustomCard from "../../components/CustomCard";
import {
  dashboardEducationFixtures,
  dashboardFixtures,
  dashboardInputFixture,
  dashboardMhpFixtures,
  dashboardOtherInfoFixture,
} from "./utilities/fixtures";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import DateTimePicker from "../../components/Form/DateTimePicker";
import { onChangeInputData } from "../../utilities/helpers";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const [inputs, setInputs] = useState(dashboardInputFixture);

  const getDashboardData = async (filterData = {}) => {
    if (loading) return;
    setLoading(true);
    const { success, data } = await postDataWithAuth({
      api_url: endpoints["getDashboardDetails"],
      data: filterData,
    });
    setLoading(false);
    if (success && data) {
      setData(data);
    }
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, inputs, setInputs);
  };

  const handleSubmit = () => {
    if (loading || !handleValidation()) return;
    getDashboardData(inputs);
  };

  const handleValidation = () => {
    let valid = true;
    let from = inputs["from_date"];
    let to = inputs["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        inputs["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  useEffect(() => {
    getDashboardData();
  }, []); // eslint-disable-line

  return (
    <main className="inner">
      <div className="bg-white">
        <div className="p-4 mb-4">
          <div className="dashboard-form">
            <form className="mb-3">
              <div className="row">
                <div className="col-sm-12 col-xl-8">
                  <div className="row">
                    <div className="col-12 col-md-5 col-xl-4">
                      <DateTimePicker
                        inputProps={{
                          type: "text",
                          isdatefield: true,
                          className: "form-control border-right-0",
                          placeholder: "Start Date",
                          name: "from_date",
                          onChange: handleInputChange,
                          value: inputs["from_date"],
                        }}
                      />
                    </div>

                    <div className="col-12 col-md-5 col-xl-4">
                      <DateTimePicker
                        inputProps={{
                          type: "text",
                          isdatefield: true,
                          className: "form-control border-right-0",
                          placeholder: "End Date",
                          name: "to_date",
                          onChange: handleInputChange,
                          value: inputs["to_date"],
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-2 col-xl-2">
                      <button
                        className="btn btn-search"
                        type="button"
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>

          <div className="dashboard-item-main">
            <div className="row">
              {dashboardFixtures.map((item, key) => (
                <Fragment key={key}>
                  <CustomCard
                    imageProps={item.imageProps}
                    heading={data?.common?.[item.key]}
                    text={item.text}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white mb-4 ">
        <h5 className="m-0 p-4 border-bottom">Literacy Program</h5>
        <div className="p-4 mb-4">
          <div className="dashboard-item-main mt-2">
            <div className="row">
              {dashboardEducationFixtures.map((item, key) => (
                <Fragment key={key}>
                  <CustomCard
                    imageProps={item.imageProps}
                    heading={data?.education?.[item.key]}
                    text={item.text}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white ">
        <h5 className="m-0 p-4  border-bottom">MHP</h5>
        <div className="p-4 mb-4">
          <div className="dashboard-item-main mt-2">
            <div className="row">
              {dashboardMhpFixtures.map((item, key) =>
                item.cards ? (
                  item.cards.map((c, key2) => (
                    <Fragment key={key2}>
                      <CustomCard
                        imageProps={c.imageProps}
                        heading={data?.mhp?.[item.key]?.[c.key]}
                        text={c.text}
                      />
                    </Fragment>
                  ))
                ) : (
                  <Fragment key={key}>
                    <CustomCard
                      imageProps={item.imageProps}
                      heading={data?.mhp?.[item.key]}
                      text={item.text}
                    />
                  </Fragment>
                )
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <h5 className="m-0 p-4  border-bottom">35 Years SBT information</h5>
        <div className="p-4  mb-4">
          <div className="dashboard-item-main mt-2">
            <div className="row">
              {dashboardOtherInfoFixture.map((item, key) => (
                <Fragment key={key}>
                  <CustomCard
                    imageProps={item.imageProps}
                    heading={data?.sbt?.[item.key]}
                    text={item.text}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Dashboard;
