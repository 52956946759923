import React, { useEffect, useState } from "react";
import { dashLogo } from "../assets/images";
import { sidebarLinkFixture } from "../utilities/fixtures";
import { Link, useLocation } from "react-router-dom";
import BeneficiaryType from "../Pages/Services/BeneficiaryType";

const Sidebar = () => {
  const [openModal, setOpenModal] = useState(false);
  const [active, setActive] = useState(0);
  const [activeChild, setActiveChild] = useState(0);
  const [dropdown, setDropdown] = useState(false);
  const [modalFor, setModalFor] = useState("");
  const location = useLocation();
  const [isSidebarCollapse, setSidebarCollapse] = useState(false);
  const [sidebarFixture] = useState(sidebarLinkFixture());

  const handleModalClose = () => {
    setModalFor("");
    setOpenModal(false);
  };

  const handleModalOpen = (modalFor) => {
    if (modalFor) {
      setModalFor(modalFor);
      setOpenModal(true);
    }
  };

  const handleSideBarCollapse = () => {
    if (window.innerWidth <= 992) {
      setSidebarCollapse(true);
      ["sidebar-closed", "sidebar-collapse"].forEach((classname) => {
        document.body.classList.add(classname);
      });
      if (document.body.classList.contains("sidebar-open")) {
        document.body.classList.remove("sidebar-open");
      }
    } else {
      setSidebarCollapse(false);
    }
  };

  const isActive = () => {
    for (let i = 0; i < sidebarFixture.length; i++) {
      if (sidebarFixture[i]?.childLinks) {
        let curr = sidebarFixture[i].childLinks;
        for (let j = 0; j < curr.length; j++) {
          const { link, modal } = curr[j];
          if (
            link === location.pathname ||
            location.pathname.includes(link) ||
            (modal && location.pathname.includes(modal))
          ) {
            setActiveChild(j);
            if (active !== i || (modal && location.pathname.includes(modal))) {
              setActive(i);
              setDropdown(true);
            }
          }
        }
      } else {
        const { link } = sidebarFixture[i];
        if (link === location.pathname) setActive(i);
        else if (location.pathname.includes(link)) setActive(i);
      }
    }
  };

  useEffect(() => {
    handleSideBarCollapse();
    window.addEventListener("resize", handleSideBarCollapse);
    isActive();
  }, [location.pathname]); //eslint-disable-line

  return (
    <>
      <aside className="main-sidebar sidebar-dark-primary">
        <Link to="/dashboard" className="brand-link">
          <img src={dashLogo} alt="SBT Logo" className="brand-image" />
          {/* <!-- <span className="brand-text font-weight-light">SBT</span> --> */}
        </Link>
        <div className="sidebar">
          <nav
            className="mt-3"
            style={
              isSidebarCollapse
                ? { marginBottom: "4rem", overflowX: "auto" }
                : { marginBottom: "4rem" }
            }
          >
            <ul className="nav nav-pills nav-sidebar flex-column">
              {sidebarFixture.map((item, key) =>
                item?.childLinks ? (
                  <li
                    className={`nav-item ${
                      active === key ? "active menu-is-opening menu-open" : ""
                    }`}
                    key={key}
                    onClick={() => {
                      setActive(key);
                      if (key !== active) setDropdown(true);
                      else setDropdown(!dropdown);
                      handleSideBarCollapse();
                    }}
                  >
                    <Link
                      to={active === key ? "#" : item.link}
                      className="nav-link"
                    >
                      <i className={`bi ${item.iconClass}`}></i>
                      {item.text}
                      <i className="right fas fa-angle-right"></i>
                    </Link>
                    <ul
                      className="nav nav-treeview"
                      style={{
                        display: active === key && dropdown ? "block" : "none",
                      }}
                    >
                      {item.childLinks.map((childItem, itemKey) => (
                        <li
                          className={`nav-item ${
                            activeChild === itemKey ? "active" : ""
                          } `}
                          key={itemKey}
                        >
                          {childItem.link ? (
                            <Link
                              to={childItem.link}
                              className="nav-link"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <i className={`far ${childItem.iconClass}`}></i>
                              {childItem.text}
                            </Link>
                          ) : (
                            <span
                              role="button"
                              className="nav-link"
                              onClick={() => handleModalOpen(childItem?.modal)}
                            >
                              <i className={`far ${childItem.iconClass}`}></i>
                              {childItem.text}
                            </span>
                          )}
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : (
                  <li
                    className={`nav-item ${active === key ? "active" : ""}`}
                    key={key}
                    onClick={() => {
                      setActive(key);
                      handleSideBarCollapse();
                    }}
                  >
                    <Link to={item.link} className="nav-link">
                      <i className={`bi ${item.iconClass}`}></i>
                      {item.text}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </nav>
        </div>
      </aside>

      {/* Beneficiary Popup */}
      <BeneficiaryType
        openModal={openModal}
        handleModalClose={handleModalClose}
        modalFor={modalFor}
      />
    </>
  );
};

export default Sidebar;
