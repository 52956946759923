import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import {
  filterInputFixture,
  produceToCwcTableFixture,
} from "./utilities/fixtures";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import FilterProduce from "./FilterProduce";
import { getCenterNames } from "../../utilities/helpers";
// import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Produce = () => {
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(filterInputFixture);
  const [produceData, setProduceData] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [filterModal, setFilterModal] = useState(false);
  // const [confirmModal, setConfirmModal] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  // const [handleConfirmation, setConfirmationHandler] = useState(() => {});

  const navigate = useNavigate();

  const handlePageSelect = (selected) => {
    if (filterDetails) getProduceData(selected, filterDetails);
    else getProduceData(selected);
  };

  const getProduceData = async (page_num, filterData = false, successCb) => {
    if (loading) return;
    setLoading(true);
    const { success, res, data } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getProduceList"](page_num)
        : endpoints["getProduceList"](),
      data: filterData ? filterDetails : {},
    });
    setLoading(false);
    if (success && data) {
      setProduceData(data);
      if (res?.pagination) setPaginationData(res.pagination);
      if (res?.image_path) setImagePath(res?.image_path);
      if (successCb) successCb();
    }
  };

  const deleteProduceCWC = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteProduceToCWC"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("deleted successfully");
      setProduceData((prev) => prev.filter((item) => item.id !== id));
      // setConfirmModal(false);
    } else {
      toast.error("Something went wrong!");
    }
  };

  // const editProduceCWC = async (id) => {
  //   if (loading || !id) return;
  //   setLoading(true);
  //   const { success } = await getDataWithAuth({
  //     api_url: endpoints["editProduceToCWC"](id),
  //   });
  //   setLoading(false);
  //   if (success) {
  //     toast.success("Edited successfully");
  //     await getProduceData(paginationData?.current_page);
  //     // setConfirmModal(false);
  //   } else {
  //     toast.error("Something went wrong!");
  //   }
  // };

  const getChildInfo = async (id, action) => {
    if (loading || !id) return;
    setLoading(true);
    const { success, img_profile, image_path, img_documents, data } =
      await getDataWithAuth({
        api_url:
          action === "edit"
            ? endpoints["getEditChildData"](id)
            : endpoints["viewChild"](id),
      });
    setLoading(false);
    if (success) {
      let childInfo = {
        img_profile,
        image_path,
        img_documents,
        data,
        action,
      };
      navigate("/child-registration/profile", { state: childInfo });
    } else {
      toast.error("Something went wrong!");
    }
  };

  // const confirmAction = (id, action) => {
  //   if (action === "delete")
  //     setConfirmationHandler(() => () => deleteProduceCWC(id));
  //   else if (action === "edit")
  //     setConfirmationHandler(() => () => editProduceCWC(id));

  //   setConfirmModal(true);
  // };

  const handleGoto = (rowdata) => {
    navigate("add", { state: rowdata });
  };

  useEffect(() => {
    async function fetchData() {
      await getProduceData();
      const data = await getCenterNames();
      if (data) setCenterNames(data);
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Produce to CWC"
          tableHeadersFixture={produceToCwcTableFixture}
          data={produceData}
          loading={loading}
          startSerialNum={paginationData?.from}
          onDelete={(rowData) => deleteProduceCWC(rowData["id"])}
          // onEdit={(rowData) => editProduceCWC(rowData["id"])}
          onEdit={(rowData) => getChildInfo(rowData["id"], "edit")}
          onView={(rowData) => getChildInfo(rowData["id"], "view")}
          onGoTo={(rowData) => handleGoto(rowData)}
          showPagination={true}
          onPageSelect={(selected) => handlePageSelect(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
          imagePath={imagePath}
        >
          <div className="button-group filter-button justify-content-md-end">
            <button
              type="button"
              className="btn btn-primary btn-primary-border"
              onClick={() => {
                setFilterModal(true);
              }}
            >
              Filters
            </button>
          </div>
        </TableArea>
      </main>

      <FilterProduce
        openModal={filterModal}
        setOpenModal={setFilterModal}
        filterDetails={filterDetails}
        setFilterDetails={setFilterDetails}
        centerNames={centerNames}
        loading={loading}
        getProduceData={getProduceData}
      />

      {/* <ConfirmationModal
        openModal={confirmModal}
        setOpenModal={setConfirmModal}
        loading={loading}
        onSubmit={handleConfirmation}
        onCancel={() => setConfirmModal(false)}
      /> */}
    </>
  );
};

export default Produce;
