export const rolesTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "User Roles", key: "name" },
  { text: "Created Date Time", key: "created_at", formatDate: true },
  { text: "Action", action: true, key: ["setPermission", "edit", "delete"] },
];

export const roleDetailsFixture = {
  name: "",
};

export const rolePermissionsFixture = {
  id: "",
  child_tracking: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
  child_registration: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
  cwc: { all: 0, create: 0, edit: 0, view: 0, delete: 0 },
};

export const permissionFixture = [
  { label: "Child Tracking", key: "child_tracking" },
  { label: "Child Registration", key: "child_registration" },
  { label: "CWC", key: "cwc" },
];

export const rolePermissionsFormFixture = {
  child_tracking: {
    all: false,
    create: false,
    edit: false,
    view: false,
    delete: false,
  },
  child_registration: {
    all: false,
    create: false,
    edit: false,
    view: false,
    delete: false,
  },
  cwc: { all: false, create: false, edit: false, view: false, delete: false },
};
