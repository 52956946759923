import React from "react";
import Modal from "../../components/Modal";
import { useNavigate } from "react-router-dom";

const BeneficiaryType = ({
  openModal = false,
  handleModalClose = () => {},
  modalFor = "",
}) => {
  const navigate = useNavigate();

  const handleBeneficiarySelection = (b_Type) => {
    if ((b_Type, modalFor))
      navigate(`/${modalFor}`, { state: b_Type, push: true });
    handleModalClose();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleModalClose}
        header="Select Beneficiaries Type"
        body_classes="py-5 px-md-5 mx-3 mx-md-5"
        modal_classes="filter-popup-medium"
      >
        <div className="button-group flex-wrap">
          <span
            className="btn btn-primary w-100 text-center d-block my-1"
            onClick={() => handleBeneficiarySelection("registered")}
          >
            Registered Beneficiaries
          </span>
          <span
            className="btn btn-primary w-100 text-center d-block my-1"
            onClick={() => handleBeneficiarySelection("non-registered")}
          >
            Non - Registered Beneficiaries
          </span>
        </div>
      </Modal>
    </>
  );
};

export default BeneficiaryType;
