import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TableArea from "../../components/DataTable/TableArea";
import { singleReportHeaderFixture } from "./utilities/fixture";
import { getDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import { toast } from "react-toastify";

const ReportDetail = () => {
  const location = useLocation();
  const endpoint_data = location.state;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  const getData = async () => {
    if (loading || !endpoint_data || !userDetails?.centerId) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: endpoints["singleReport"](userDetails.centerId, endpoint_data),
    });
    setLoading(false);
    if (success && data) {
      setList(data);
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getData();
  }, [userDetails, endpoint_data]); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          tableHeadersFixture={singleReportHeaderFixture}
          data={list}
          loading={loading}
        ></TableArea>
      </main>
    </>
  );
};

export default ReportDetail;
