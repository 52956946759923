import React, { useEffect, useState } from "react";
import { centerDetailsFixture } from "./utilities/fixtures";
import validate from "../../utilities/validation";
import { toast } from "react-toastify";
import { downIcon } from "../../assets/images";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { endpoints } from "../../_api/endpoints";
import { isActionAllowed } from "../../utilities/helpers";

const AddCenter = () => {
  const location = useLocation();
  let center = location.state;

  const [centerDetails, setCenterDetails] = useState(
    center || centerDetailsFixture
  );
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [centerPoints, setCenterPoints] = useState([]);
  const navigate = useNavigate();

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setCenterDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleValidation = (target) => {
    let errs = { ...errors };
    let validation = true;

    for (let key in centerDetails) {
      if ((key === "email" || key === "phone") && centerDetails[key]) {
        errs[key] = validate[key](centerDetails[key]);
      } else if (key === "centertypeId" && !centerDetails[key])
        errs[key] = `The Center Field is required`;
      else if (key === "name" && !centerDetails[key])
        errs[key] = "Please enter center name";
      if (!!errs[key]) {
        toast.error(errs[key]);
        validation = false;
        return false;
      }
    }

    setErrors(errs);

    for (let key in errs) if (errs[key]) return false;

    return validation;
  };

  const getCenterPoints = async () => {
    if (loading) return;
    setLoading(true);
    const { data } = await getDataWithAuth({
      api_url: endpoints["centers_points"],
    });
    setLoading(false);
    if (data) setCenterPoints(data);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      const { success, errors, message } = await postDataWithAuth({
        api_url: centerDetails["id"]
          ? endpoints["updateCenter"]
          : endpoints["addCenter"],
        data: { ...centerDetails },
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        navigate("/center-management");
      } else if (errors) {
        if (typeof errors === "object")
          toast.error(errors[Object.keys(errors)[0]][0]);
      } else {
        toast.error(message);
      }
    }
  };

  const handleBack = () => {
    setCenterDetails(centerDetailsFixture);
    navigate("/center-management");
  };

  useEffect(() => {
    getCenterPoints();
    if (!isActionAllowed("create")) {
      navigate(-1);
    }
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <div className="bg-white mb-3">
          <div className="px-4 py-3 border-bottom header-page">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="d-flex align-items-center mb-3 mb-md-0">
                  <Link to={"/center-management"} className=" btn btn-back">
                    <img src={downIcon} alt="" />
                  </Link>
                  <h5 className="page-title mb-0 pl-3">Add Center Details</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="registration-form">
              <form>
                <div className="row">
                  <div className="col-12 col-sm-6">
                    <label>
                      Center <span className="required">*</span>
                    </label>
                    <div className="form-group mb-4">
                      <div className="custom_select">
                        <select
                          className="form-control"
                          name="centertypeId"
                          value={centerDetails.centertypeId}
                          onChange={handleOnChange}
                        >
                          <option value="">Select</option>
                          {centerPoints &&
                            centerPoints.map((center, key) => (
                              <option key={key} value={center?.id}>
                                {center?.centername}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>
                      Center Name <span className="required">*</span>
                    </label>
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={centerDetails.name}
                        onChange={handleOnChange}
                        placeholder="Enter Name"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>Center Email Id</label>
                    <div className="form-group mb-4">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={centerDetails.email}
                        onChange={handleOnChange}
                        placeholder="Enter Email"
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>Center phone No. </label>
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        name="phone"
                        value={centerDetails.phone}
                        onChange={handleOnChange}
                        className="form-control"
                        placeholder="Center phone No."
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <label>Address</label>
                    <div className="form-group mb-4">
                      <input
                        type="text"
                        name="address"
                        value={centerDetails.address}
                        onChange={handleOnChange}
                        className="form-control"
                        placeholder="Type here..."
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <label>Status</label>
                    <div className="form-group mb-4">
                      <div className="custom_select">
                        <select
                          className="form-control"
                          name="status"
                          value={centerDetails.status}
                          onChange={handleOnChange}
                        >
                          <option value={1}>
                            Enable
                          </option>
                          <option value={0}>Disable</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 ">
                    <div className="button-group display-flex ">
                      <button
                        type="reset"
                        className="btn btn-primary btn-primary-border btn-reset"
                        onClick={() => {
                          handleBack();
                        }}
                      >
                        Back
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary btn-search"
                        disabled={loading}
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default AddCenter;
