export const rehabilitationTableHeaderFixture = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Unique Id", objkey: "child_data", key: "uniqueId" },
  { text: "Aadhar No.", objkey: "child_data", key: "aadharno" },
  { text: "CWC Case No.", objkey: "child_data", key: "cwccaseno" },
  { text: "Type Of Exit", key: "exit_type" },
  { text: "Reason of Exit", key: "history_status" },
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  { text: "Created By", objkey: "user_data_history", key: "name" },
];

export const filterFormFixure = (optionData) => {
  return [
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Child Name",
        name: "name",
      },
      className: "col-12 col-sm-6",
      parentClassName: "row",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Child Name",
        labelProps: { htmlFor: "name", className: "" },
      },
      row: "new",
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "from_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "From",
        labelProps: { htmlFor: "from_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        isdatefield: true,
        className: "form-control border-right-0",
        placeholder: "DD-MM-YY",
        name: "to_date",
      },
      className: "col-12 col-sm-6",
      label: {
        text: "To",
        labelProps: { htmlFor: "to_date", className: "" },
      },
    },
    {
      inputProps: {
        type: "text",
        className: "form-control",
        placeholder: "Enter Unique Id",
        name: "uniqueId",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      label: {
        text: "Unique Id",
        labelProps: { htmlFor: "uniqueId", className: "" },
      },
    },
    {
      inputProps: {
        type: "select",
        className: "form-control",
        placeholder: "Select Center Name",
        name: "centerId",
      },
      className: "col-12 col-sm-6",
      parentProps: { input: { className: "form-group mb-4" } },
      options: optionData?.centerNames ? [...optionData.centerNames] : [],
      optionProps: { textKey: "name", valueKey: "id" },
      label: {
        text: "Center Name",
        labelProps: { htmlFor: "centerId", className: "" },
      },
    },
  ];
};

export const filterFormInputFixture = {
  name: "",
  from_date: "",
  to_date: "",
  uniqueId: "",
  centerId: "",
};

export const commonTableFixture1 = [
  { text: "S No.", key: "serial_num" },
  { text: "Name", objkey: "child_data", key: "name" },
  { text: "Unique Id", objkey: "child_data", key: "uniqueId" },
  { text: "Aadhar No.", objkey: "child_data", key: "aadharno" },
  { text: "CWC Case No.", objkey: "child_data", key: "cwccaseno" },
];

export const commonTableFixture2 = [
  { text: "Center Name", objkey: "center_data", key: "name" },
  { text: "Created Date-Time", key: "created_at", formatDate: true },
  { text: "Modified Date-Time", key: "updated_at", formatDate: true },
  { text: "Created By", objkey: "user_data_history", key: "name" },
];

export const afterCareTableHeaderFixture = [
  ...commonTableFixture1,
  { text: "Reason of Exit", key: "history_status" },
  ...commonTableFixture2,
];

export const higherEducationTableHeaderFixture = [
  ...commonTableFixture1,
  { text: "Comment", key: "comment" },
  { text: "Date", key: "transfer_date" },
  ...commonTableFixture2,
];

export const dropoutTableFixture = [
  ...commonTableFixture1,
  { text: "Reason of Exit", key: "history_status" },
  ...commonTableFixture2,
];

export const transferTableFixture = [
  ...commonTableFixture1,
  { text: "Created By", objkey: "user_data_history", key: "name" },
];

export const restorationTableHFixture = [
  ...commonTableFixture1,
  { text: "Reason of Exit", key: "history_status" },
  ...commonTableFixture2,
];

export const dataForFixture = [
  {
    link: "/education",
    status_key: "education",
    tableFixture: [
      ...commonTableFixture1,
      { text: "Education Type", key: "education_type", formatVal: true },
      { text: "Educational Date", key: "transfer_date" },
      ...commonTableFixture2,
    ],
    title: "Education",
  },
  {
    link: "/medical",
    status_key: "medical_issue",
    tableFixture: [
      ...commonTableFixture1,
      { text: "Medical Issue", key: "medical_issues", formatVal: true },
      { text: "Medical Date", key: "transfer_date" },
      ...commonTableFixture2,
    ],
    title: "Medical",
  },
  {
    link: "/mhp",
    status_key: "mhp",
    tableFixture: [
      ...commonTableFixture1,
      { text: "Mhp", key: "other", formatVal: true },
      ...commonTableFixture2,
    ],
    title: "MHP",
  },
  {
    link: "/restoration",
    status_key: "restoration",
    tableFixture: restorationTableHFixture,
    title: "Child Restoration List",
  },
  {
    link: "/restoration-followup",
    status_key: "restoration_follow_up",
    tableFixture: restorationTableHFixture,
    title: "Child Restoration Follow-Up List",
  },
  {
    link: "/rehabilitation-set-free",
    status_key: "rehabilitation",
    tableFixture: rehabilitationTableHeaderFixture,
    title: "Child Rehabilitation List",
  },
  {
    link: "/after-care",
    status_key: "after_care",
    tableFixture: afterCareTableHeaderFixture,
    title: "Child After-Care",
  },
  {
    link: "/higher-education",
    status_key: "higher_education",
    tableFixture: higherEducationTableHeaderFixture,
    title: "Child Higher Education",
  },
  {
    link: "/dropout",
    status_key: "dropout",
    tableFixture: dropoutTableFixture,
    title: "Child Dropout List",
  },
  {
    link: "/run-away",
    status_key: "run_away",
    tableFixture: dropoutTableFixture,
    title: "Child Run-Away",
  },
  {
    link: "/short-stay",
    status_key: "short_stay",
    tableFixture: dropoutTableFixture,
    title: "Child Short Stay",
  },
  {
    link: "/transfer",
    status_key: "transfer",
    tableFixture: transferTableFixture,
    title: "Child Transfer",
  },
  {
    link: "/other-ngo",
    status_key: "other_ngo",
    tableFixture: [...commonTableFixture1, ...commonTableFixture2],
    title: "Other NGO",
  },
  {
    link: "/io-police",
    status_key: "io_police",
    tableFixture: [...commonTableFixture1, ...commonTableFixture2],
    title: "IO Police",
  },
  {
    link: "/transfer-to-home-state-cwc",
    status_key: "transfer_to_home_state_CWC",
    tableFixture: [...commonTableFixture1, ...commonTableFixture2],
    title: "Child Transfer to Home State CWC",
  },
];
