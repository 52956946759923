import axios from "axios";
import { errors } from "../utilities/fixtures";
import { toast } from "react-toastify";

export const postData = async ({ api_url = "", data = {} }) => {
  try {
    const query = await fetch(api_url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
    const response = await query.json();

    if (response?.success)
      return {
        data: response?.data,
        image_path: response?.image_path,
        message: response?.message,
        success: response?.success,
        errors: response?.errors,
      };
    else
      return {
        message: response.message || "Server not responding",
        errors: response?.errors,
      };
  } catch (error) {
    return { error: errors[error.message] || error.message };
  }
};

// export const postDataWithAuth = async ({
//   api_url = "",
//   data = {},
//   token = "",
//   contentType = "application/json",
// }) => {
//   try {
//     const query = await fetch(api_url, {
//       method: "POST",
//       headers: {
//         // "Content-Type": contentType,
//         Authorization: `Bearer ${getAccessToken() || token}`,
//       },
//       body: contentType === "multipart/form-data" ? data : JSON.stringify(data),
//     });
//     const response = await query.json();

//     if (response?.success)
//       return {
//         data: response?.data,
//         message: response?.message,
//         success: response?.success,
//         errors: response?.errors,
//         res: response,
//       };
//     else
//       return {
//         message: response.message || "Server not responding",
//         errors: response?.errors,
//       };
//   } catch (error) {
//     return { error: errors[error.message] || error.message };
//   }
// };

export const postDataWithAuth = async ({
  api_url = "",
  data = {},
  token = "",
  contentType = "application/json",
}) => {
  try {
    const query = await axios({
      method: "post",
      headers: {
        "Content-Type": contentType,
        Authorization: `Bearer ${getAccessToken() || token}`,
      },
      url: api_url,
      data: data,
    });
    const response = query.data;
    if (response?.success)
      return {
        data: response?.data,
        message: response?.message,
        success: response?.success,
        errors: response?.errors,
        res: response,
      };
    else
      return {
        message: response.message || "Server not responding",
        errors: response?.errors,
      };
  } catch (error) {
    if (
      getAccessToken() &&
      error?.response?.data?.message === "Unauthenticated."
    ) {
      localStorage.removeItem("user");
      window.location.reload();
    }
    return { error: errors[error.message] || error.message };
  }
};

export const getDataWithAuth = async ({ api_url = "", token }) => {
  try {
    const query = await fetch(api_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken() || token}`,
      },
    });
    const response = await query.json();
    if (response?.success)
      return {
        // data: response?.data,
        // message: response?.message,
        // success: response?.success,
        // errors: response?.errors,
        // pagination: response?.pagination,
        ...response,
      };
    else
      return {
        message: response?.message || "Server not responding",
        errors: response?.errors,
      };
  } catch (error) {
    return { error: errors[error.message] || error.message };
  }
};

export const getData = async ({ api_url = "" }) => {
  try {
    const query = await fetch(api_url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const response = await query.json();
    if (response?.success)
      return {
        data: response?.data,
        message: response?.message,
        success: response?.success,
        errors: response?.errors,
      };
    else
      return {
        message: response.message || "Server not responding",
        errors: response?.errors,
      };
  } catch (error) {
    return { error: errors[error.message] || error.message };
  }
};

export const getAccessToken = () => {
  const user = JSON.parse(localStorage.getItem("user"));
  if (user) return user?.token || "";
};
