import React from "react";
import Modal from "../../components/Modal";
import { onChangeInputData } from "../../utilities/helpers";

const FilterUser = ({
  openModal = false,
  setOpenModal = () => {},
  filterDetails = {},
  setFilterDetails = () => {},
  roles = [],
  centerNames = [],
  loading = false,
  getAllUsers = () => {},
}) => {
  const handleModalClose = () => {
    setOpenModal(false);
  };
  const filterUsers = async (reset = false) => {
    if (reset) {
      setFilterDetails({});
      getAllUsers(1);
    } else getAllUsers(1, filterDetails);
    handleModalClose();
  };
  return (
    <Modal
      open={openModal}
      onClose={handleModalClose}
      header="Search User Details Filters"
      modal_classes="filter-popup-medium"
    >
      <form>
        <div className="row mb-3">
          <div className="col-12">
            <label>Center Name</label>
            <div className="form-group mb-3">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="centertypeId"
                  value={filterDetails.centertypeId}
                  onChange={(e) =>
                    onChangeInputData(e, filterDetails, setFilterDetails)
                  }
                >
                  <option value="">Select</option>
                  {centerNames &&
                    centerNames.map((center, key) => (
                      <option key={key} value={center?.id}>
                        {center?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12">
            <label>Role</label>
            <div className="form-group mb-3">
              <div className="custom_select">
                <select
                  className="form-control"
                  name="role_id"
                  value={filterDetails.role_id}
                  onChange={(e) =>
                    onChangeInputData(e, filterDetails, setFilterDetails)
                  }
                >
                  <option value="">Select</option>
                  {roles &&
                    roles.map((role, key) => (
                      <option key={key} value={role?.id}>
                        {role?.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-12">
            <label>User Name</label>
            <div className="form-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Name"
                name="name"
                value={filterDetails.name}
                onChange={(e) =>
                  onChangeInputData(e, filterDetails, setFilterDetails)
                }
              />
            </div>
          </div>
          <div className="col-12 ">
            <div className="button-group display-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn btn-primary btn-primary-border btn-reset"
                disabled={loading}
                onClick={() => (loading ? {} : filterUsers(true))}
              >
                Reset
              </button>
              <button
                type="button"
                className="btn btn-primary btn-search"
                disabled={loading}
                onClick={() => (loading ? {} : filterUsers())}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};

export default FilterUser;
