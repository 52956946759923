import React, { useEffect, useState } from "react";
import { postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import {
  capitalizeWords,
  getCenterNames,
  onChangeInputData,
} from "../../utilities/helpers";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import { filterFormFixture, filterInputFixture } from "./utilities/fixture";
import { toast } from "react-toastify";
import { CSVLink } from "react-csv";

const GroupReport = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [filterInputs, setFilterInputs] = useState(filterInputFixture);
  const [headers, setHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [centerNames, setCenterNames] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [csvData, setCsvData] = useState([]);

  const getData = async (filterData = {}) => {
    if (loading) return;
    setLoading(true);
    const { success, data } = await postDataWithAuth({
      api_url: endpoints["groupReport"],
      data: filterData,
    });
    setLoading(false);
    if (success && data) {
      setData(data);
      if (openModal) setOpenModal(false);
    } else if (filterData) {
      toast.error("Something went wrong!");
    }
  };

  const createHeaders = (list) => {
    let arr = ["S.No", "Headings"];
    for (let i = 0; i < list.length; i++) {
      arr.push(list[i]?.name);
    }
    arr.push("Total");
    setHeaders(arr);
  };

  const getRowData = (key, ind) => {
    let arr = [];
    if (key === "previousyeardata") {
      arr.push(" ");
      arr.push("Previous Balance");
    } else {
      arr.push(ind);
      arr.push(capitalizeWords(key, "_"));
    }
    for (let i = 0; i < Object.keys(data[key]).length; i++) {
      if (i in data[key]) arr.push(data[key][i]?.total);
    }
    if ("totalsum" in data[key]) arr.push(data[key]["totalsum"]);
    if (key === "previousyeardata") setPrevData(arr);
    return arr;
  };

  const getTableData = () => {
    let rows = [];
    let arr = [];
    let ind = 1;
    for (let key in data) {
      if (key !== "AllCenterList" && key !== "previousyeardata") {
        arr = getRowData(key, ind);
        ind++;
        rows.push(arr);
      }
    }
    setTableData(rows);
  };

  const handleInputChange = (e) => {
    onChangeInputData(e, filterInputs, setFilterInputs);
  };

  const handleSubmit = () => {
    if (loading || !handleValidation()) return;
    getData(filterInputs);
  };

  const handleValidation = () => {
    let valid = true;
    let from = filterInputs["from_date"];
    let to = filterInputs["to_date"];
    if (from && to && to < from) {
      valid = false;
      toast.info("From Date Should Be Less Than To Date");
    } else if ((!from && to) || (from && !to)) {
      valid = false;
      let msg = `${
        filterInputs["from_date"] ? "Select To Date" : "Select From Date"
      }`;
      toast.info(msg);
    }
    return valid;
  };

  const handleReset = () => {
    setFilterInputs(filterInputFixture);
    getData(filterInputFixture);
  };

  useEffect(() => {
    async function fetchData() {
      const centerData = await getCenterNames();
      if (centerData) setCenterNames(centerData);
    }
    fetchData();
    getData(filterInputFixture);
  }, []); // eslint-disable-line

  useEffect(() => {
    if (data["AllCenterList"]) createHeaders(data["AllCenterList"]);
    if (data["previousyeardata"]) getRowData("previousyeardata");
    if (data) getTableData();
  }, [data]); // eslint-disable-line

  useEffect(() => {
    let rows = [...tableData];
    rows.unshift(headers, prevData);
    setCsvData(rows);
  }, [headers, prevData, tableData]);

  return (
    <>
      <main className="inner">
        <div className="bg-white">
          <div className="filter-header px-4 px-lg-4 py-3 border-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-4">
                <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                  Report List
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <div className="button-group filter-button justify-content-md-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary-border"
                    onClick={() => setOpenModal(true)}
                  >
                    Filters
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    style={{ gap: 0 }}
                  >
                    <CSVLink
                      data={csvData}
                      style={{ color: "#fff" }}
                      filename={"group-report.csv"}
                    >
                      Export
                    </CSVLink>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="filter-list">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {headers.map((header, key) => (
                        <th key={key}>{header}</th>
                      ))}
                    </tr>
                    <tr>
                      {data["previousyeardata"] &&
                        prevData.map((header, key) => (
                          <th key={key}>{header}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data &&
                      tableData.map((arr, key1) => (
                        <tr key={key1}>
                          {arr.map((item, key2) => (
                            <td key={key2}>{item}</td>
                          ))}
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        header={`Report List Filters`}
        modal_classes="filter-popup-medium"
      >
        <Form
          fixture={filterFormFixture({ centerNames: centerNames })}
          inputs={filterInputs}
          loading={loading}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          handleBack={handleReset}
          nextBtnText="Search"
          nextBtnClasses="btn-search"
          backBtnText="Reset"
          backBtnClasses="btn-primary-border btn-reset"
          BtnParentClasses="justify-content-end mt-3"
          showBackBtn={true}
        />
      </Modal>
    </>
  );
};

export default GroupReport;
