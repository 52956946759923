import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { postData } from "../_api/apiCall";
import { loginFixture } from "../utilities/fixtures";
import validate from "../utilities/validation";
import { logo } from "../assets/images";
import { endpoints } from "../_api/endpoints";

const Login = () => {
  const [loginInputs, setLoginInputs] = useState(loginFixture);
  const [loading, setLoading] = useState(false);
  const [userDetails] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const navigate = useNavigate();

  const handleOnChange = ({ target }) => {
    const { name, value } = target;
    setLoginInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleValidation = useCallback(() => {
    let validation = true;

    for (let key in loginInputs) {
      let err = validate[key](loginInputs[key]);
      if (!!err) {
        toast.error(err);
        return false;
      }
    }

    return validation;
  },[loginInputs]);

  const handleSubmit = useCallback(async () => {
    if (handleValidation()) {
      setLoading(true);
      const { data, message, image_path } = await postData({
        api_url: endpoints["login"],
        data: { email: loginInputs.emailId, password: loginInputs.password },
      });
      setLoading(false);
      if (data) {
        if (message) toast.success(message);
        localStorage.setItem(
          "user",
          JSON.stringify({ ...data, imagePath: image_path })
        );
        navigate("/dashboard");
      } else if (message) toast.error("Login Failed");
    }
  },[handleValidation,loginInputs,navigate]);

  const checkKeyPress = useCallback((e) => {
    const { keyCode } = e;
    if (keyCode === 13) {
      handleSubmit();
    }
  },[handleSubmit]);

  useEffect(() => {
    window.addEventListener("keydown", checkKeyPress);
    return () => {
      window.removeEventListener("keydown", checkKeyPress);
    };
  }, [checkKeyPress]);

  useEffect(() => {
    if (userDetails?.token) navigate("/");
    else document.title = "SBT Admin | Login";
  }, [userDetails, navigate]);

  return (
    <div className="hold-transition">
      <div className="d-block d-md-flex align-items-center justify-content-center bg-white login-main">
        <div className="login-left"></div>
        <div className="login-right">
          <div className="in">
            <div className="login-box mx-auto">
              <div className="login-logo">
                <Link to={"/login"}>
                  <img className="img-fluid" src={logo} alt="" />
                </Link>
              </div>

              <div className="card shadow-none">
                <div className="card-body login-card-body p-0">
                  <div className="text-center py-3 py-md-5">
                    <h2>Login to your account</h2>
                    <p className="m-0">
                      Welcome Back! Please enter your details
                    </p>
                  </div>

                  <form>
                    <label htmlFor="email">Email ID</label>
                    <div className="input-group mb-3 mb-md-5">
                      <input
                        type="email"
                        name="emailId"
                        className="form-control"
                        placeholder="Enter your email id"
                        onChange={handleOnChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-envelope"></span>
                        </div>
                      </div>
                    </div>
                    <label htmlFor="email">Password</label>
                    <div className="input-group mb-3 mb-md-5">
                      <input
                        name="password"
                        type="password"
                        className="form-control"
                        placeholder="Enter your password"
                        onChange={handleOnChange}
                      />
                      <div className="input-group-append">
                        <div className="input-group-text">
                          <span className="fas fa-lock"></span>
                        </div>
                      </div>
                    </div>
                    <div className="btn-field text-center ">
                      <button
                        type="button"
                        className="btn btn-primary "
                        disabled={loading}
                        onClick={() => (loading ? {} : handleSubmit())}
                      >
                        Log in
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="Copyright-text text-center">
              © Copyright 2024 Salaam Baalak Trust Delhi. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
