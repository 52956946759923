import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import {
  UserTableHeaderFixture,
  filterDetailsFixtire,
  userDetailsFixture,
} from "./utilities/fixture";
import { endpoints } from "../../_api/endpoints";
import AddUser from "./AddUser";
import { toast } from "react-toastify";
import FilterUser from "./FilterUser";
import { getCenterNames, isActionAllowed } from "../../utilities/helpers";

const UserManagement = () => {
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(filterDetailsFixtire);
  const [users, setUsers] = useState([]);
  const [paginationData, setPaginationData] = useState();
  const [centerNames, setCenterNames] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [userDetails, setUserDetails] = useState(userDetailsFixture);
  const [imagePath, setImagePath] = useState("");

  const handlePageSelect = (selected) => {
    if (filterDetails) getAllUsers(selected, filterDetails);
    else getAllUsers(selected);
  };

  const getUserRoles = async () => {
    if (loading) return;
    setLoading(true);

    const { success, data } = await getDataWithAuth({
      api_url: endpoints["getRoles"],
    });
    setLoading(false);
    if (success && data) {
      setUserRoles(data);
    }
  };

  const getAllUsers = async (page_num, filterData = false) => {
    if (loading) return;
    setLoading(true);
    const { res, data } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getUsers"](page_num)
        : endpoints["getUsers"](),
      data: filterData ? filterDetails : {},
    });
    setLoading(false);
    if (data) {
      setUsers(data);
      if (res?.pagination) setPaginationData(res.pagination);
      if (res?.image_path) setImagePath(res?.image_path);
    }
  };

  const handleEdit = (userData) => {
    setUserDetails(userData);
    setOpenModal(true);
  };

  const deleteUser = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteUser"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("User deleted successfully");
      setUsers((prev) => prev.filter((item) => item.id !== id));
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    async function fetchData() {
      await getUserRoles();
      const centerData = await getCenterNames();
      if (centerData) setCenterNames(centerData);
      await getAllUsers();
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Users Management List"
          tableHeadersFixture={UserTableHeaderFixture}
          data={users}
          loading={loading}
          startSerialNum={paginationData?.from}
          onDelete={(rowData) => deleteUser(rowData["id"])}
          onEdit={(rowData) => handleEdit(rowData)}
          showPagination={true}
          onPageSelect={(selected) => handlePageSelect(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
        >
          <div className="button-group filter-button justify-content-md-end">
            <button
              type="button"
              className="btn btn-primary btn-primary-border"
              onClick={() => {
                setFilterModal(true);
              }}
            >
              Filters
            </button>
            {isActionAllowed("create") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => {
                  setUserDetails(userDetailsFixture);
                  setOpenModal(true);
                }}
              >
                Add User
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      <AddUser
        openModal={openModal}
        setOpenModal={setOpenModal}
        userDetails={userDetails}
        setUserDetails={setUserDetails}
        centerNames={centerNames}
        roles={userRoles}
        loading={loading}
        setLoading={setLoading}
        imagePath={imagePath}
        cb={() => getAllUsers(paginationData?.current_page)}
      />

      <FilterUser
        openModal={filterModal}
        setOpenModal={setFilterModal}
        filterDetails={filterDetails}
        setFilterDetails={setFilterDetails}
        roles={userRoles}
        centerNames={centerNames}
        loading={loading}
        getAllUsers={getAllUsers}
      />
    </>
  );
};

export default UserManagement;
