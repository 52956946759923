import React, { useEffect } from "react";
import { closeIcon } from "../assets/images";

const Modal = ({
  children,
  header = "",
  open = false,
  onClose = () => {},
  body_classes = "",
  modal_classes = "",
}) => {
  useEffect(() => {
    let elemDiv;
    if (open) {
      document.body.classList.add("modal-open");
      elemDiv = document.createElement("div");

      ["modal-backdrop", "fade", "show"].forEach((classname) => {
        elemDiv.classList.add(classname);
      });
      document.body.appendChild(elemDiv);
    }
    const handleClick = ({ target }) => {
      if (
        typeof target?.className === "string" &&
        target.className.includes("fade")
      ) {
        onClose();
      }
    };

    window.addEventListener("mousedown", handleClick, false);
    return () => {
      if (elemDiv) document.body.removeChild(elemDiv);
      document.body.classList.remove("modal-open");
    };
  }, [open]); // eslint-disable-line

  return (
    open && (
      <div
        className={`modal fade filter-popup show ${
          modal_classes ? modal_classes : ""
        }`}
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered ">
          <div className="modal-content shadow-none border-0">
            <div className="modal-header px-4">
              <h5 className="modal-title page-title">{header}</h5>
              <button
                type="button"
                className="close"
                onClick={() => {
                  onClose();
                }}
              >
                <img src={closeIcon} alt="" />
              </button>
            </div>

            <div
              className={`modal-body px-4  ${body_classes ? body_classes : ""}`}
            >
              {children}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
