import React, { Fragment } from "react";
import Input from "./Input";
import Loader from "../Loader";

const Form = ({
  children,
  className = "",
  errors = {},
  fixture = [],
  inputs = {},
  loading = false,
  onChange = () => {},
  style = {},
  backBtnClasses = "",
  nextBtnClasses = "",
  BtnParentClasses = "",
  showBackBtn = false,
  showNextBtn = true,
  showDeleteBtn = false,
  showUpdateBtn = false,
  handleBack = () => {},
  onSubmit = () => {},
  handleUpdate = () => {},
  handleDelete = () => {},
  backBtnText = "Back",
  nextBtnText = "Next",
  readOnlyFields = false,
  loaderColor = "#fff",
  showLoader = false,
}) => {
  const handleSubmit = () => {
    if (loading) return;
    else onSubmit();
  };

  const GetForm = () => {
    let ind = 0;
    let form = [];

    const NewInput = (item) => {
      return (
        <Input
          {...item}
          imageProps={{
            ...item.imageProps,
          }}
          inputProps={{
            ...item.inputProps,
            ...(readOnlyFields ? { onChange: () => {} } : { onChange }),
            ...(item.inputProps.type === "radio"
              ? {
                  checked:
                    inputs[item.inputProps.name] === item.inputProps.value,
                }
              : item.inputProps.type === "checkbox"
              ? {
                  checked: inputs[item.inputProps.name],
                }
              : item.inputProps.type === "file"
              ? {
                  file_name:
                    typeof inputs[item.inputProps.name] === "object"
                      ? inputs[item.inputProps.name]?.name
                      : inputs[item.inputProps.name],
                }
              : {
                  value: inputs[item.inputProps.name]
                    ? inputs[item.inputProps.name]
                    : "",
                }),
          }}
          readOnlyFields={readOnlyFields}
        ></Input>
      );
    };

    const GetInput = () => {
      let children = [];
      // while (true) {
      //   // if (
      //   //   !fixture[ind]["relatedField"] ||
      //   //   (fixture[ind]["relatedField"] &&
      //   //     inputs[fixture[ind]["relatedField"]] ===
      //   //       fixture[ind]["relatedFieldVal"])
      //   // )
      //   children.push(
      //     <div className={`${fixture[ind].className || ""}`} key={ind}>
      //       {NewInput(fixture[ind])}
      //     </div>
      //   );
      //   if (++ind === fixture.length || fixture[ind].row === "new") break;
      // }

      while (true) {
        if (fixture[ind]?.radioGroup) {
          let p = [];
          for (let k = 0; k < fixture[ind]?.radioFields.length; k++) {
            p.push(
              <Fragment key={`c-${ind}-${k}`}>
                {NewInput(fixture[ind].radioFields[k])}
              </Fragment>
            );
          }
          children.push(
            <div className={`${fixture[ind].className || ""}`} key={ind}>
              {fixture[ind]?.groupLabel?.text ? (
                <label
                  {...(fixture[ind].groupLabel?.labelProps
                    ? fixture[ind].groupLabel.labelProps
                    : {})}
                >
                  {fixture[ind].groupLabel.text}
                  {fixture[ind]?.required ? (
                    <span className="required"> *</span>
                  ) : (
                    ""
                  )}
                </label>
              ) : null}
              <div className="d-flex mb-3">{p}</div>
            </div>
          );
        } else {
          children.push(
            <div className={`${fixture[ind].className || ""}`} key={ind}>
              {NewInput(fixture[ind])}
            </div>
          );
        }
        if (++ind === fixture.length || fixture[ind].row === "new") break;
      }

      return children;
    };

    while (ind < fixture.length) {
      form.push(
        <div className={fixture[ind].parentClassName || ""} key={ind}>
          {GetInput()}
        </div>
      );
    }

    return form;
  };

  return (
    <form autoComplete="off" className={className} style={style}>
      {children}
      {GetForm()}

      {!!fixture.length && (
        <div className="col-12 ">
          <div
            className={`button-group display-flex mb-4 ${
              BtnParentClasses ? BtnParentClasses : ""
            }`}
          >
            {showUpdateBtn && (
              <button
                type="button"
                className={`btn btn-secondary`}
                style={{ borderRadius: "10px" }}
                disabled={loading}
                onClick={() => (loading ? {} : handleUpdate(inputs))}
              >
                Update
              </button>
            )}
            {showDeleteBtn && (
              <button
                type="button"
                className={`btn btn-danger`}
                style={{ borderRadius: "10px" }}
                disabled={loading}
                onClick={() => (loading ? {} : handleDelete(inputs))}
              >
                Delete
              </button>
            )}
            {showBackBtn && (
              <button
                type="button"
                className={`btn btn-primary ${
                  backBtnClasses ? backBtnClasses : ""
                }`}
                disabled={loading}
                onClick={() => (loading ? {} : handleBack(true))}
              >
                {backBtnText}
              </button>
            )}
            {showNextBtn && (
              <button
                type="button"
                className={`btn btn-primary ${
                  nextBtnClasses ? nextBtnClasses : ""
                }`}
                disabled={loading}
                onClick={() => (loading ? {} : handleSubmit())}
              >
                {loading && showLoader ? (
                  <Loader color={loaderColor} />
                ) : (
                  nextBtnText
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </form>
  );
};

export default Form;
