import React, { useEffect, useState } from "react";
import { getDataWithAuth } from "../../_api/apiCall";
import { mealListTableFixture } from "./utilities/fixtures";
import { endpoints } from "../../_api/endpoints";
import TableArea from "../../components/DataTable/TableArea";

const MealManagement = () => {
  const [mealList, setMealList] = useState([]);
  const [loading, setLoading] = useState(false);

  const getMeals = async () => {
    if (loading) return;
    setLoading(true);

    const { success, data } = await getDataWithAuth({
      api_url: endpoints["getMealList"],
    });
    setLoading(false);
    if (success && data) {
      setMealList(data);
    }
  };

  useEffect(() => {
    getMeals();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Meal Details"
          tableHeadersFixture={mealListTableFixture}
          data={mealList}
          loading={loading}
        >
        </TableArea>
      </main>
    </>
  );
};

export default MealManagement;
