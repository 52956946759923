import React, { useEffect, useState } from "react";

const CustomPagination = ({
  currPage = 1,
  items = [],
  onPageSelect = () => {},
  perPage = 10,
  paginationData = {},
}) => {
  const [currentPage, setCurrentPage] = useState(
    paginationData?.current_page || currPage
  );
  const [ranges, setRanges] = useState([]);
  const [boxLimit, setBoxLimit] = useState(3); // eslint-disable-line

  const pageCount =
    paginationData?.last_page || Math.ceil(items.length / perPage);

  const total_item = paginationData?.total || items.length;

  const handlePageSelection = (selected) => {
    if (currentPage!==selected && selected >= 1 && selected <= pageCount) {
      pageBoxes(selected);
      setCurrentPage(selected);
      onPageSelect(selected);
    }
  };

  const pageBoxes = (page_num) => {
    let first_page = 1;
    let last_page = pageCount;
    let arr = [];

    if (page_num - first_page + 1 <= boxLimit) {
      if (page_num + 1 >= pageCount || first_page === last_page)
        arr.push({ start: first_page, end: last_page });
      else {
        if (first_page + (boxLimit - 1) >= pageCount) {
          arr.push({ start: first_page, end: last_page, spread: false });
        } else {
          arr.push({
            start: first_page,
            end:
              first_page + (boxLimit - 1) === page_num
                ? page_num + 1
                : first_page + (boxLimit - 1),
            spread: false,
          });
          arr.push({ spread: true });
          arr.push({ start: last_page, end: last_page, spread: false });
        }
      }
    } else if (last_page - page_num + 1 <= boxLimit) {
      if (page_num - 1 <= first_page)
        arr.push({ start: first_page, end: last_page });
      else {
        if (last_page - (boxLimit - 1) < first_page) {
          arr.push({ start: first_page, end: last_page, spread: false });
        } else {
          arr.push({ start: first_page, end: first_page, spread: false });
          arr.push({ spread: true });
          arr.push({
            start:
              last_page - (boxLimit - 1) === page_num
                ? page_num - 1
                : last_page - (boxLimit - 1),
            end: last_page,
            spread: false,
          });
        }
      }
    } else {
      arr.push({ start: first_page, end: first_page, spread: false });
      arr.push({ spread: true });
      arr.push({ start: page_num - 1, end: page_num + 1, spread: false });
      arr.push({ spread: true });
      arr.push({ start: last_page, end: last_page, spread: false });
    }
    setRanges(arr);
  };

  const getPageSelectors = (range) => {
    let pages = [];
    for (let i = range.start; i <= range.end; i++) {
      pages.push(
        <li
          className="page-item"
          onClick={() => handlePageSelection(i)}
          key={`k-${i}`}
        >
          <span className={`page-link ${currentPage === i ? "active" : ""}`}>
            {i}
          </span>
        </li>
      );
    }
    return pages;
  };

  useEffect(() => {
    if (paginationData?.current_page)
      setCurrentPage(paginationData.current_page);
  }, [paginationData]);

  useEffect(() => {
    pageBoxes(currentPage);
  }, [currentPage, paginationData]); // eslint-disable-line

  return (
    <>
      {items.length ? (
        <div className="d-flex justify-content-between align-items-center flex-column flex-md-row ">
          <span className="text-pages">
            Page {currentPage} of {pageCount} | Displaying{" "}
            {(currentPage - 1) * perPage + 1} to{" "}
            {Math.min(currentPage * perPage, total_item)} of {total_item}
          </span>
          <nav aria-label="Page navigation example">
            <ul className="pagination m-0">
              <li
                className="page-item"
                onClick={() => handlePageSelection(1)}
                key={"prev1"}
              >
                <span className="page-link gray_pagin">
                  <span className="bi bi-chevron-double-left"></span>
                </span>
              </li>
              <li
                className="page-item"
                key={"prev2"}
                onClick={() => handlePageSelection(currentPage - 1)}
              >
                <span className="page-link">
                  <span className="bi bi-chevron-left"></span>
                </span>
              </li>
              {ranges.map((range, key) =>
                range.spread ? (
                  <li className="page-item" key={key}>
                    <span className="page-link">...</span>
                  </li>
                ) : (
                  getPageSelectors(range)
                )
              )}
              <li
                className="page-item"
                key={"next1"}
                onClick={() => handlePageSelection(currentPage + 1)}
              >
                <span className="page-link">
                  <span className="bi bi-chevron-right"></span>
                </span>
              </li>
              <li
                className="page-item"
                key={"next2"}
                onClick={() => handlePageSelection(pageCount)}
              >
                <span className="page-link gray_pagin">
                  <span className="bi bi-chevron-double-right"></span>
                </span>
              </li>
            </ul>
          </nav>
        </div>
      ) : null}
    </>
  );
};

export const getPaginatedData = (
  dataToPaginate = [],
  dataPerPage = 10,
  selectedPage = 1
) =>
  dataToPaginate.filter(
    (_, index) =>
      index >= (selectedPage - 1) * dataPerPage &&
      index < selectedPage * dataPerPage
  );

export default CustomPagination;
