import React from "react";
import { banner, mission, vision } from "../../assets/images";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <main className="inner">
      <div className="bg-white">
        <div className="container-fluid">
          <div className="about-main p-4">
            <div className="about-main-inner">
              <div className="row">
                <div className="col-md-4">
                  <div className="about-img">
                    <img src={banner} className="mw-100" alt="" />
                  </div>
                </div>
                <div className="col-md-8">
                  <div className="about-content pt-3 pt-md-0 pl-md-4">
                    <h2 className="about-title d-inline-flex align-items-center">
                      Who We Are
                    </h2>
                    <div className="sub-title py-2">
                      Since the inception of SBT in 1988, we have provided
                      holistic support to street & working Children in India.
                    </div>
                    <p>
                      SBT provides a sensitive and caring environment for street
                      and working children, and those in difficult
                      circumstances. It seeks to provide a platform for children
                      to realise their full potential, their right to a safe and
                      secure space, education, and health and nutrition.
                    </p>
                    <p>
                      SBT aims at creating a nurturing environment that can
                      foster the physical and mental growth of street and
                      working children in difficult circumstances. The mission
                      is to help children experience their childhood in a safe
                      environment and turn their dreams into reality!
                    </p>
                    <Link to={"#"} className="btn btn-primary mt-2">
                      Know More
                    </Link>
                  </div>
                </div>
              </div>
              <div className="border p-3 py-md-3 px-md-4 mt-4 vm-box">
                <div className="row align-items-center">
                  <div className="col-md-10">
                    <div className="vision-content">
                      <h2 className="about-title d-inline-flex align-items-center">
                        Mission
                      </h2>
                      <p className="m-0 mt-2">
                        SBT provides a sensitive and caring environment for
                        street and working children, and those in difficult
                        circumstances. It seeks to provide a platform for
                        children to realise their full potential, their right to
                        a safe and secure space, education, and health and
                        nutrition.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <div className="vm-img text-md-right">
                      <img src={mission} className="mw-100" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="border p-3 py-md-3 px-md-4 mt-4 vm-box ">
                <div className="row align-items-center">
                  <div className="col-md-10 order-md-2">
                    <div className="mission-content">
                      <h2 className="about-title d-inline-flex align-items-center">
                        Vision
                      </h2>
                      <p className="m-0 mt-2">
                        SBT aims at creating a nurturing environment that can
                        foster the physical and mental growth of street and
                        working children in difficult circumstances. The mission
                        is to help children experience their childhood in a safe
                        environment and turn their dreams into reality!
                      </p>
                    </div>
                  </div>
                  <div className="col-md-2 order-md-1">
                    <div className="vm-img">
                      <img src={vision} className="mw-100" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default AboutUs;
