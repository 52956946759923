import React from "react";

const CustomCard = ({ imageProps, heading = "", text = "" }) => {
  return (
    <>
      <div className="col-12 col-md-6 col-xl-4 mb-2 mb-lg-4">
        <div className="small-box d-flex align-items-center p-2 p-xl-4 shadow-none">
          <div className="icon_image">
            {imageProps && (
              <img src={imageProps.src} alt="icon" className="mw-100" />
            )}
          </div>
          <div className="inner m-0 p-0 pl-3 pl-xl-4 text-left">
            <h3 className="mb-1">{heading}</h3>
            <p className="mb-0">{text}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomCard;
