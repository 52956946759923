import {
  annualTour,
  childIcon,
  cwcIcon,
  // cwcSettingIcon,
  exchangeIcon,
  // exitChilrensIcon,
  formalSchoolIcon,
  higherEducationIcon,
  jobPlacement,
  lighSkillEducation,
  literacyProgram,
  mealProvided,
  medicalSupport,
  nonFormalSchool,
  nonFormalSchoolIcon,
  openSchoolingIcon,
  openSchoolingIcon2,
  producedIcon,
  refralSessionIcon,
  // rescuedIcon,
  restorationIcon,
  shareSessionIcon,
  totalBeneficiaries,
  totalShelterProvided,
  vocationalTrainingSkills,
} from "../../../assets/images";

export const dashboardFixtures = [
  {
    imageProps: { alt: "icon", src: childIcon },
    key: "children_registered",
    text: "Number of Children Registered",
  },
  {
    imageProps: { alt: "icon", src: exchangeIcon },
    key: "transferred_cases",
    text: "Restoration Cases (Boys & Girls)",
  },
  {
    imageProps: { alt: "icon", src: cwcIcon },
    key: "refer_cwc",
    text: "Total Referred from CWC",
  },
  // {
  //   imageProps: { alt: "icon", src: cwcSettingIcon },
  //   key: "home_state_cwc",
  //   text: "Transfer to Home State CWC",
  // },
  // {
  //   imageProps: { alt: "icon", src: exitChilrensIcon },
  //   key: "exit_children",
  //   text: "Exit Children’s",
  // },
  {
    imageProps: { alt: "icon", src: producedIcon },
    key: "produce_cwc",
    text: "Total Produced to CWC",
  },
  // {
  //   imageProps: { alt: "icon", src: rescuedIcon },
  //   key: "rescued",
  //   text: "Rescued (Operations)",
  // },
  {
    imageProps: { alt: "icon", src: annualTour },
    key: "annul_tour",
    text: "Annual Tour",
  },
];

export const dashboardEducationFixtures = [
  {
    imageProps: { alt: "icon", src: formalSchoolIcon },
    key: "formal_school",
    text: "Formal School Education",
  },
  {
    imageProps: { alt: "icon", src: nonFormalSchoolIcon },
    key: "nfe",
    text: "Non-Formal School Education",
  },
  {
    imageProps: { alt: "icon", src: openSchoolingIcon },
    key: "nios",
    text: "National Institute of Open Schooling Education",
  },
  {
    imageProps: { alt: "icon", src: higherEducationIcon },
    key: "higher_education",
    text: "Higher Education",
  },
  {
    imageProps: { alt: "icon", src: vocationalTrainingSkills },
    key: "vocational_training",
    text: "Vocational Training",
  },
  {
    imageProps: { alt: "icon", src: jobPlacement },
    key: "job_placement",
    text: "Jobs",
  },
];

export const dashboardMhpFixtures = [
  {
    imageProps: { alt: "icon", src: shareSessionIcon },
    key: "group_sessions",
    text: "Group Session Organised",
  },
  {
    key: "group_sessions_all",
    cards: [
      {
        imageProps: { alt: "icon", src: shareSessionIcon },
        key: "total_childregId",
        text: "Group Session - Total Child Registered",
      },
      {
        imageProps: { alt: "icon", src: shareSessionIcon },
        key: "session_count",
        text: "Group Session - Session Count",
        subtext: "Session Count",
      },
    ],
  },
  {
    key: "lse_sessions_all",
    cards: [
      {
        imageProps: { alt: "icon", src: shareSessionIcon },
        key: "total_childregId",
        text: "LSE Session - Total Child Registered",
      },
      {
        imageProps: { alt: "icon", src: shareSessionIcon },
        key: "session_count",
        text: "LSE Session - Session Count",
      },
    ],
  },
  {
    imageProps: { alt: "icon", src: openSchoolingIcon2 },
    key: "psycho_social_session",
    text: "Psycho-Social Session",
  },
  {
    imageProps: { alt: "icon", src: refralSessionIcon },
    key: "referral_session",
    text: "Referral Session",
  },
  {
    imageProps: { alt: "icon", src: lighSkillEducation },
    key: "lse_sessions",
    text: "Life Skill Education Session",
  },
  {
    imageProps: { alt: "icon", src: nonFormalSchool },
    key: "career_counseling",
    text: "Career Counselling",
  },
];

export const dashboardOtherInfoFixture = [
  {
    imageProps: { alt: "icon", src: totalBeneficiaries },
    key: "beneficiaries",
    text: "Total Beneficiaries",
  },
  {
    imageProps: { alt: "icon", src: totalShelterProvided },
    key: "total_shelter",
    text: "Total Shelter Provied",
  },
  {
    imageProps: { alt: "icon", src: literacyProgram },
    key: "literacy_program",
    text: "Literacy Program",
  },
  {
    imageProps: { alt: "icon", src: medicalSupport },
    key: "medical_support",
    text: "Medical Support",
  },
  {
    imageProps: { alt: "icon", src: restorationIcon },
    key: "restoration",
    text: "Restoration(Reunited With The Family)",
  },
  {
    imageProps: { alt: "icon", src: vocationalTrainingSkills },
    key: "vocational_training",
    text: "Vocationa Training/Skills",
  },
  {
    imageProps: { alt: "icon", src: jobPlacement },
    key: "job_placement",
    text: "Job Placement",
  },
  {
    imageProps: { alt: "icon", src: mealProvided },
    key: "meal_provided",
    text: "Meal Provided",
  },
];

export const dashboardInputFixture = {
  from_date: "",
  to_date: "",
};
