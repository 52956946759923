import React from "react";
import DateTimePicker from "./DateTimePicker";
import SearchableSelect from "./SearchableSelect";

const Input = ({
  children,
  childProps,
  dropdownProps = {},
  error,
  imageProps = {},
  inputProps = {},
  label = {},
  hideSelectOption = false,
  options = [],
  optionProps = {},
  otherImageProps = {},
  parentProps = { input: {}, image: {} },
  required = false,
  readOnlyFields = false,
}) => {
  const preventSelectOpen = (e) => {
    if (readOnlyFields) e.preventDefault();
  };
  return inputProps.type === "select" ? (
    <>
      <label {...label.props}>
        {label.text} {required ? <span className="required">*</span> : ""}{" "}
      </label>
      <div className="form-group mb-3" {...parentProps.input}>
        <div className={`custom_select ${parentProps.selectClassName || ""}`}>
          <select {...inputProps} onMouseDown={preventSelectOpen}>
            {!hideSelectOption && (
              <option value="">
                {inputProps.placeholder ? inputProps.placeholder : "Select"}
              </option>
            )}
            {options.map((option, key) => (
              <option
                key={key}
                value={
                  typeof option === "object"
                    ? optionProps && optionProps?.valueKey in option
                      ? option[optionProps.valueKey]
                      : "value" in option
                      ? option.value
                      : option.text
                    : option
                }
              >
                {typeof option === "object"
                  ? optionProps && optionProps?.textKey in option
                    ? option[optionProps.textKey]
                    : option.name || option.text || option.option
                  : option}
              </option>
            ))}
          </select>
        </div>
        {!!error && <span className="input-error">{error}</span>}
        {children}
      </div>
    </>
  ) : inputProps.type === "searchableSelect" ? (
    <>
      <>
        <label {...label.props}>
          {label.text} {required ? <span className="required">*</span> : ""}{" "}
        </label>
        <div className="form-group mb-3" {...parentProps.input}>
          <div
            className={`custom_search_select ${
              parentProps.selectClassName || ""
            }`}
          >
            <SearchableSelect
              inputProps={inputProps}
              options={options}
              optionProps={optionProps}
              readOnlyFields={readOnlyFields}
            />
          </div>
          {!!error && <span className="input-error">{error}</span>}
          {children}
        </div>
      </>
    </>
  ) : inputProps.type === "file" ? (
    <>
      <label {...label.props}>
        {label.text} {required ? <span className="required">*</span> : ""}{" "}
      </label>
      <div className="form-group mb-3" {...parentProps.input}>
        <div className="form-control">
          {readOnlyFields ? (
            inputProps?.file_name &&
            ["http:", "https:"].some((el) =>
              inputProps.file_name.includes(el)
            ) ? (
              <div className="file-custom">
                {!inputProps.file_name.includes("null") ? (
                  <>
                    <div className="label">
                      {inputProps.file_name.split("/").pop()}
                    </div>
                    <a
                      href={inputProps.file_name}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>view file</span>
                    </a>
                  </>
                ) : (
                  "No File Choosen"
                )}
              </div>
            ) : (
              <div className="file-custom">No File Choosen</div>
            )
          ) : (
            <div className="file-custom">
              <div className="label">
                {inputProps?.file_name
                  ? inputProps.file_name
                  : "Choose file to upload"}
              </div>
              <span>Upload</span>
              <input {...inputProps} />
            </div>
          )}
        </div>
      </div>
    </>
  ) : inputProps.isdatefield ? (
    <>
      <label {...label.props}>
        {label.text} {required ? <span className="required">*</span> : ""}{" "}
      </label>
      <DateTimePicker
        inputProps={inputProps}
        parentProps={parentProps}
        readOnlyFields={readOnlyFields}
      />
    </>
  ) : inputProps.type === "textarea" ? (
    <>
      <label {...label.props}>
        {label.text} {required ? <span className="required">*</span> : ""}{" "}
      </label>
      <div className="form-group mb-3" {...parentProps.input}>
        <textarea {...inputProps} />
      </div>
    </>
  ) : inputProps.type === "radio" ? (
    <>
      <div className="form-check" {...parentProps.input}>
        <input {...inputProps} />
        <label {...label.props}>{label.text}</label>
      </div>
    </>
  ) : (
    <>
      <label {...label.props}>
        {label.text} {required ? <span className="required">*</span> : ""}{" "}
      </label>
      <div className="form-group mb-3" {...parentProps.input}>
        <input {...inputProps} />
      </div>
    </>
  );
};

export default Input;
