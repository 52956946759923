import React, { Fragment, useEffect, useState } from "react";
import CustomCard from "../../components/CustomCard";
import DataTable from "../../components/DataTable/DataTable";
import CustomPagination from "../../components/Pagination/CustomPagination";
import {
  CenterManagement_cardFixture,
  filterDetailsFixtire,
  tableHeadersFixture,
} from "./utilities/fixtures";
import Modal from "../../components/Modal";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import {
  getCenterNames,
  isActionAllowed,
  onChangeInputData,
} from "../../utilities/helpers";
import { noRecordFound } from "../../assets/images";

const CenterManagement = () => {
  const [centers, setCenters] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [centerPoints, setCenterPoints] = useState([]);
  const [paginationData, setPaginationData] = useState({});
  const [loading, setLoading] = useState(false);
  const [filterDetails, setFilterDetails] = useState(filterDetailsFixtire);
  const [centerData, setCenterData] = useState({});
  const [centerNames, setCenterNames] = useState([]);

  const navigate = useNavigate();

  const getCenterTypeValue = (id) => {
    return centerPoints.filter((curr) => curr.id === id)[0]?.centername;
  };

  const handlePageSelect = (selected) => {
    if (filterDetails) getAllCenters(selected, filterDetails);
    else getAllCenters(selected);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const getCenterPoints = async () => {
    if (loading) return;
    setLoading(true);
    const { data } = await getDataWithAuth({
      api_url: endpoints["centers_points"],
    });
    setLoading(false);
    if (data) setCenterPoints(data);
  };

  const getAllCenters = async (page_num, filterData = false) => {
    if (loading) return;
    setLoading(true);
    const { res, data } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getCenters"](page_num)
        : endpoints["getCenters"](),
      data: filterData ? filterDetails : {},
    });
    setLoading(false);
    if (data) {
      setCenters(data);
      if (res?.pagination) setPaginationData(res.pagination);
    }
  };

  const handleDeleteCenter = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteCenter"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("Center deleted successfully");
      setCenters((prev) => prev.filter((item) => item.id !== id));
      getCenterDetail();
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleStatusChange = async (e, rowData) => {
    const { checked, name } = e.target;
    if (loading || !rowData["id"]) return;
    setLoading(true);
    setCenters((prev) =>
      prev.map((item) => ({
        ...item,
        ...(item.id === rowData.id ? { [name]: checked } : {}),
      }))
    );
    const { success } = await getDataWithAuth({
      api_url: endpoints["updateCenterStatus"](rowData["id"], checked ? 1 : 0),
    });
    setLoading(false);

    if (success) {
      toast.success("Status changed successfully");
    } else {
      setCenters((prev) =>
        prev.map((item) => ({
          ...item,
          ...(item.id === rowData.id ? { [name]: !checked } : {}),
        }))
      );
      toast.error("Something went wrong!");
    }
  };

  const getCenterDetail = async () => {
    if (loading) return;
    setLoading(true);
    const { success, data } = await getDataWithAuth({
      api_url: endpoints["getCenterData"],
    });
    setLoading(false);
    if (success && data) {
      setCenterData(data);
    }
  };

  const filterCenters = async (reset = false) => {
    if (reset) {
      setFilterDetails({});
      await getAllCenters(1);
    } else await getAllCenters(1, filterDetails);
    handleModalClose();
  };

  useEffect(() => {
    async function fetchData() {
      await getCenterPoints();
      let data = await getCenterNames();
      setCenterNames(data);
      await getAllCenters();
      await getCenterDetail();
    }
    fetchData();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <div className="bg-white mb-4 ">
          <div className="p-4">
            <div className="dashboard-item-main">
              <div className="row">
                {CenterManagement_cardFixture.map((item, key) => (
                  <Fragment key={key}>
                    <CustomCard
                      imageProps={item.imageProps}
                      heading={
                        item.key === "total_centers"
                          ? centerData["total_centers"]
                          : centerData?.sbtcenters?.[item.key]
                      }
                      text={item.text}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white">
          <div className="filter-header px-4 px-lg-4 py-3 border-bottom">
            <div className="row justify-content-between align-items-center">
              <div className="col-12 col-md-4">
                <h5 className="mb-3 mt-2 mt-md-0 mb-md-0 page-title">
                  Center Details
                </h5>
              </div>
              <div className="col-12 col-md-8">
                <div className="button-group filter-button justify-content-md-end">
                  <button
                    type="button"
                    className="btn btn-primary btn-primary-border"
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Filters
                  </button>
                  {isActionAllowed("create") ? (
                    <Link to={"add-center"} className="btn btn-primary btn-add">
                      Add Center
                    </Link>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="p-4">
            <div className="filter-list">
              <DataTable
                tableHeadersFixture={tableHeadersFixture}
                data={centers}
                loading={loading}
                keyname="centertypeId"
                startSerialNum={paginationData?.from}
                getKeyVal={getCenterTypeValue}
                onDelete={(rowData) => handleDeleteCenter(rowData["id"])}
                onEdit={(rowData) => navigate("add-center", { state: rowData })}
                onStatusChange={handleStatusChange}
              />
            </div>
            <CustomPagination
              items={centers}
              onPageSelect={handlePageSelect}
              paginationData={paginationData}
              perPage={paginationData?.per_page}
            />
            {!centers.length && (
              <div className="no-record-main">
                <div className="img-no">
                  <img src={noRecordFound} className="mw-100" alt="" />
                </div>
                <h5>No Records Found!</h5>
              </div>
            )}
          </div>
        </div>
      </main>

      {/* Filter modal */}
      <Modal
        open={openModal}
        onClose={handleModalClose}
        header="Search Center Details Filters"
        modal_classes="filter-popup-medium"
      >
        <form>
          <div className="row mb-3">
            <div className="col-12">
              <label>Center</label>
              <div className="form-group mb-3">
                <div className="custom_select">
                  <select
                    className="form-control"
                    name="centertypeId"
                    value={filterDetails.centertypeId}
                    onChange={(e) =>
                      onChangeInputData(e, filterDetails, setFilterDetails)
                    }
                  >
                    <option value="">Select</option>
                    {centerPoints &&
                      centerPoints.map((center, key) => (
                        <option key={key} value={center?.id}>
                          {center?.centername}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12">
              <label>Center Name</label>
              <div className="form-group mb-3">
                <div className="custom_select">
                  <select
                    className="form-control"
                    name="name"
                    value={filterDetails.name}
                    onChange={(e) =>
                      onChangeInputData(e, filterDetails, setFilterDetails)
                    }
                  >
                    <option value="">Select</option>
                    {centerNames &&
                      centerNames.map((center, key) => (
                        <option key={key} value={center?.name}>
                          {center?.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-12 ">
              <div className="button-group display-flex justify-content-end mt-3">
                <button
                  type="reset"
                  className="btn btn-primary btn-primary-border btn-reset"
                  disabled={loading}
                  onClick={() => {
                    filterCenters(true);
                  }}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-search"
                  disabled={loading}
                  onClick={() => {
                    filterCenters();
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CenterManagement;
