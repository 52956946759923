import React, { useEffect, useState } from "react";
import { childProfileActionNavFixture } from "./utilities/fixtures";

const ActionBtn = ({ setTitle = () => {} }) => {
  const [showDropdown, setShowDropdown] = useState(false);

  const handleAction = (action) => {
    setTitle(action);
  };

  useEffect(() => {
    const handleClick = ({ target }) => {
      if (
        typeof target?.className === "string" &&
        !target.className.includes("dropdown")
      ) {
        setShowDropdown(false);
      }
    };

    window.addEventListener("mousedown", handleClick);
  }, [showDropdown]);

  return (
    <>
      <div
        className={`dropdown-user dropdown ml-md-auto ${
          showDropdown ? "show" : ""
        }`}
      >
        <span
          className="btn btn-primary text-white dropdown-toggle  d-flex align-items-center"
          role="button"
          style={{ gap: 0 }}
          onClick={() => setShowDropdown(!showDropdown)}
        >
          Action
        </span>
        <ul
          className={`dropdown-menu user-list shadow-none ${
            showDropdown ? "show" : ""
          }`}
        >
          {childProfileActionNavFixture.map((item) => (
            <li key={item.id}>
              <span
                style={{ cursor: "pointer" }}
                className="dropdown-item"
                onClick={() => handleAction(item.text)}
              >
                {item.text}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default ActionBtn;
