import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

const DashboardLayout = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [userDetails, setUserDetails] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );

  useEffect(() => {
    setUserDetails(JSON.parse(localStorage.getItem("user")) || {});

    ["hold-transition", "sidebar-mini", "layout-fixed"].forEach((classname) => {
      document.body.classList.add(classname);
    });

    setTimeout(() => {
      document.body.classList.remove("hold-transition");
    }, 150);

    if (location.pathname === "/") navigate("/dashboard");

    return () => {
      ["sidebar-mini", "layout-fixed"].forEach((classname) => {
        document.body.classList.remove(classname);
      });
    };
  }, [location.pathname, navigate]); // eslint-disable-line

  useEffect(() => {
    if (!userDetails?.token) navigate("/login");
  }, [userDetails, navigate]);

  return (
    <>
      <Header />
      <Sidebar />
      <div style={{ minHeight: "100vh", backgroundColor: "#f4f6f9" }}>
        <section className="content-wrapper">
          <Outlet />
        </section>
      </div>
      <Footer />
    </>
  );
};

export default DashboardLayout;
