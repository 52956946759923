import React, { useEffect, useState } from "react";
import Modal from "../../components/Modal";
import Form from "../../components/Form/Form";
import {
  quickAddFormFixture,
  quickAddInputFixture,
} from "./utilities/fixtures";
import { onChangeInputData } from "../../utilities/helpers";
import { postDataWithAuth } from "../../_api/apiCall";
import { toast } from "react-toastify";
import { endpoints } from "../../_api/endpoints";
import validate from "../../utilities/validation";

const QuickAdd = ({
  loading = false,
  openModal = false,
  setOpenModal = () => {},
  centerNames = [],
  setLoading = () => {},
  getAllRegistrations = () => {},
}) => {
  const [formFixture, setFormFixture] = useState();

  const [inputs, setInputs] = useState(quickAddInputFixture);

  const handleInputChange = (e) => {
    onChangeInputData(e, inputs, setInputs);
  };

  const handleModalClose = () => {
    setOpenModal(false);
    setInputs(quickAddInputFixture);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      let formData = new FormData();
      for (let key in inputs) {
        if (inputs[key]) formData.append(key, inputs[key]);
      }
      setLoading(true);
      const { success, errors, message } = await postDataWithAuth({
        api_url: endpoints["quickAdd"],
        data: formData,
        contentType: "multipart/form-data",
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        getAllRegistrations();
        handleModalClose();
      } else if (errors) {
        if (typeof errors === "object")
          toast.error(errors[Object.keys(errors)[0]][0]);
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const handleValidation = () => {
    let valid = true;
    for (let key in inputs) {
      if (key === "cwccaseno") {
        if (inputs["ReferredBy"] === "refer") {
          let err = validate[key](inputs[key]);
          if (err) {
            toast.error(err);
            return false;
          }
        }
      } else {
        let err = "";
        err = validate[key](inputs[key]);
        if (key === "image" && !inputs[key]) {
          err = "Image is required";
        }
        if (err) {
          if (key === "name") err = "Name is required";
          toast.error(err);
          return false;
        }
      }
    }
    return valid;
  };

  useEffect(() => {
    setFormFixture(quickAddFormFixture({ centerNames: centerNames }));
  }, [centerNames]);

  return (
    <Modal
      open={openModal && !loading}
      onClose={handleModalClose}
      header="Quick Add Child"
    >
      <div className="registration-form">
        <Form
          fixture={formFixture}
          inputs={inputs}
          loading={loading}
          onChange={handleInputChange}
          onSubmit={handleSubmit}
          handleBack={handleModalClose}
          showBackBtn={true}
          backBtnText="Close"
          backBtnClasses="btn-primary-border"
          nextBtnText="Save"
          BtnParentClasses="justify-content-end mt-3"
        />
      </div>
    </Modal>
  );
};

export default QuickAdd;
