import React, { forwardRef, useRef } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utilities/helpers";

const CustomDateInput = forwardRef(
  ({ inputProps = {}, parentProps = {}, value, onClick }, ref) => {
    return (
      <div className="input-group mb-4 " {...parentProps.input}>
        <input
          {...inputProps}
          isdatefield={value.toString(inputProps.isdatefield)}
          value={value}
          onClick={onClick}
          ref={ref}
          onChange={() => {}}
        />
        <span
          className="input-group-text bg-transparent border-left-0 ml-n1"
          onClick={onClick}
        >
          <i className="bi bi-calendar4"></i>
        </span>
      </div>
    );
  }
);

const DateTimePicker = ({
  inputProps = {},
  parentProps = {},
  readOnlyFields = false,
}) => {
  const inputRef = useRef();
  return (
    <>
      <div className="customDatePickerWidth">
        <DatePicker
          selected={inputProps.value}
          onChange={(date) =>
            inputProps.onChange({
              target: { name: inputProps.name, value: getFormattedDate(date) },
            })
          }
          dateFormat="dd-MM-yyyy"
          showMonthDropdown={true}
          showYearDropdown={true}
          scrollableYearDropdown={true}
          yearDropdownItemNumber={100}
          customInput={
            <CustomDateInput
              inputProps={inputProps}
              parentProps={parentProps}
              inputRef={inputRef}
            />
          }
          readOnly={readOnlyFields}
        />
      </div>
    </>
  );
};

export default DateTimePicker;
