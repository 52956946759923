import React from "react";

const Footer = () => {
  return (
    <>
      <footer className="main-footer">
        <p className="mb-0 text-center">
          Copyright &copy; 2014-2024 Salaam Baalak Trust. All rights reserved.
        </p>
      </footer>
    </>
  );
};

export default Footer;
