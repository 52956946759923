import React, { useEffect, useState } from "react";
import TableArea from "../../components/DataTable/TableArea";
import {
  TagsTableHeaderFixture,
  tagDetailsFixture,
} from "./utilities/fixtures";
import { getDataWithAuth, postDataWithAuth } from "../../_api/apiCall";
import { endpoints } from "../../_api/endpoints";
import Modal from "../../components/Modal";
import { isActionAllowed, onChangeInputData } from "../../utilities/helpers";
import { toast } from "react-toastify";

const TagManagement = () => {
  const [loading, setLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [paginationData, setPaginationData] = useState({});
  const [tagDetails, setTagDetails] = useState({});

  const getTags = async (page_num) => {
    if (loading) return;
    setLoading(true);

    const { res } = await postDataWithAuth({
      api_url: page_num
        ? endpoints["getTags"](page_num)
        : endpoints["getTags"](),
      data: {},
    });
    setLoading(false);
    if (res?.success && res?.data) {
      setTags(res?.data);
      if (res?.pagination) setPaginationData(res.pagination);
    }
  };

  const handleEdit = (tagData) => {
    setTagDetails({ id: tagData["id"], name: tagData["name"] });
    setOpenModal(true);
  };

  const handleSubmit = async () => {
    if (!loading && handleValidation()) {
      setLoading(true);
      const { success, message } = await postDataWithAuth({
        api_url: tagDetails["id"]
          ? endpoints["updateTag"]
          : endpoints["addTag"],
        data: { ...tagDetails },
      });
      setLoading(false);
      if (success) {
        if (message) toast.success(message);
        await getTags(paginationData?.current_page);
        setOpenModal(false);
      } else {
        if (message) toast.error(message);
        else toast.error("Something went wrong!");
      }
    }
  };

  const deleteTag = async (id) => {
    if (loading || !id) return;
    setLoading(true);
    const { success } = await getDataWithAuth({
      api_url: endpoints["deleteTag"](id),
    });
    setLoading(false);
    if (success) {
      toast.success("Tag deleted successfully");
      setTags((prev) => prev.filter((item) => item.id !== id));
    } else {
      toast.error("Something went wrong!");
    }
  };

  const handleValidation = () => {
    if (tagDetails.name) return true;
    else toast.error("Tag name is required.");
  };

  useEffect(() => {
    getTags();
  }, []); // eslint-disable-line

  return (
    <>
      <main className="inner">
        <TableArea
          heading="Tag Details"
          tableHeadersFixture={TagsTableHeaderFixture}
          data={tags}
          loading={loading}
          startSerialNum={paginationData?.from}
          onDelete={(rowData) => deleteTag(rowData["id"])}
          onEdit={(rowData) => handleEdit(rowData)}
          showPagination={true}
          onPageSelect={(selected) => getTags(selected)}
          paginationData={paginationData}
          perPage={paginationData?.per_page}
        >
          <div className="button-group filter-button justify-content-md-end">
            {isActionAllowed("create") ? (
              <span
                className="btn btn-primary btn-add"
                onClick={() => {
                  setTagDetails(tagDetailsFixture);
                  setOpenModal(true);
                }}
              >
                Add Tag
              </span>
            ) : null}
          </div>
        </TableArea>
      </main>

      {/* Add role popup */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        header={tagDetails["id"] ? "Update Tag" : "Add Tag"}
        modal_classes="add-role-popup filter-popup-medium"
      >
        <form>
          <div className="mb-3 row">
            <div className="col-12">
              <label>Tag Name</label>
              <div className="form-group mb-3">
                <input
                  type="text"
                  name="name"
                  value={tagDetails.name}
                  className="form-control"
                  placeholder="Enter Name"
                  onChange={(e) =>
                    onChangeInputData(e, tagDetails, setTagDetails)
                  }
                />
              </div>
            </div>
            <div className="col-12 ">
              <div className="button-group display-flex justify-content-end mt-5">
                <button
                  type="button"
                  className="btn btn-primary btn-primary-border "
                  disabled={loading}
                  onClick={() => {
                    setTagDetails({});
                    setOpenModal(false);
                  }}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  disabled={loading}
                  onClick={() => handleSubmit()}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TagManagement;
